<template>
  <div class="video_wap">
    <div class="video_box" v-show="isnotbtn">
      <video
        v-show="isnotbtn"
        ref="video"
        class="videoClass"
        autoplay
      ></video>
    </div>
    <canvas ref="canvas" width="400" height="300" v-show="false" />
    <!-- 截图展示图片 -->
    <img ref="screenshot" v-show="false">
  </div>
</template>  

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    isCameraShow: {
      type: Boolean
    },
  },
  // 数据对象
  data () {
    return {
      isnotbtn: false,
      isOnce: true
    };
  },
  // 事件处理器
  methods: {
    // 截图
    screenshot() {
      // 获取视频
      const video = this.$refs.video;
      // 获取截图的地址
      const screenshot = this.$refs.screenshot;
      // 获取canvas
      const canvas = this.$refs.canvas;
      // 渲染一个2d平面的视图
      const ctx = canvas.getContext("2d");
      // 设置canvas 视图文件地址和大小
      ctx.drawImage(video, 0, 0, 400, 300);
      // 将数据转为base64赋值给img标签的src属性
      screenshot.src = canvas.toDataURL("image/png");
      const imgBase = canvas.toDataURL("image/png")
      
      // 图片上传服务器
      this.$uoloadImg({
        file: this.dataURLtoBlob(imgBase),
        progress: (e)=>{
        },
        success: (e)=>{
          this.$emit('setDataURL', e)
        }
      })
    },

    // base64转bold文件
    dataURLtoBlob(dataurl){
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 调用摄像头
    callCamera() {
      // H5调用电脑摄像头API
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          this.isnotbtn = true;
          // 摄像头开启成功
          this.$refs["video"].srcObject = success;
          if(this.isOnce){
            setTimeout(()=>{
              this.screenshot()
            },5000)
          }
          this.isOnce = false
          // 实时拍照效果
          this.$refs["video"].play();
          this.timer = setInterval(()=>{
            this.screenshot()
          }, 300000)
        })
        .catch((error) => {
        });
    },

    // 关闭摄像头
    closeCamera() {
      if (!this.$refs["video"].srcObject) {
        this.dialogCamera = false;
        return;
      }
      let stream = this.$refs["video"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].srcObject = null;
      this.isnotbtn = false;
    },
    
    
  },
  // 生命周期-实例创建完成后调用
  created () {},
  // 生命周期-实例挂载后调用
  mounted () {
    setTimeout(()=>{
      this.callCamera()
    },2000)
  },
  beforeDestroy(){
    clearTimeout(this.timer);
    this.closeCamera()
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: { }
}
</script>
<style lang="less" scoped>
.video_wap{
  padding-bottom: 16px;
}
.video_box{
  margin: 0 auto;
  overflow: hidden;
  width: 217px;
  height: 217px;
  position: relative;
  text-align: center;
  .videoClass {
    width: 209px;
    height: 209px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 55;
  }
}
</style>